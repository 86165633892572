import React from 'react';
import { SC } from 'src/styles/theme';
import { isBrandOV, isBrandDS, isBrandAC, isBrandAP } from 'src/constants/main';
import { PriceVatExcludedStyledAC } from './AC/PriceVatExcludedStyledAC';
import { PriceVatExcludedStyledAP } from './AP/PriceVatExcludedStyledAP';
import { PriceVatExcludedStyledDS } from './DS/PriceVatExcludedStyledDS';
import { PriceVatExcludedStyledOV } from './OV/PriceVatExcludedStyledOV';
import { PriceVatExcludedStyled } from './PriceVatExcludedStyled';

export interface IPriceVatExcluded extends SC {
    price: any;
}

export const PriceVatExcluded = (props: IPriceVatExcluded) => {
    if (isBrandAC) return <PriceVatExcludedStyledAC {...props} />;
    if (isBrandAP) return <PriceVatExcludedStyledAP {...props} />;
    if (isBrandDS) return <PriceVatExcludedStyledDS {...props} />;
    if (isBrandOV) return <PriceVatExcludedStyledOV {...props} />;
    return <PriceVatExcludedStyled {...props} />;
};
