import styled from 'styled-components';
import { rem } from 'polished';
import { NeedHandSectionStyled } from '../NeedHandSectionStyled';
export const NeedHandSectionStyledOV = styled(NeedHandSectionStyled)`
    .needHandSection {
        &_title {
            font-size: ${rem(34)};
            color: ${({ theme }) => theme.colors.black2};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        }

        &_phone {
            font-size: ${rem(22)};
            color: ${({ theme }) => theme.colors.black2};
            text-decoration: none;
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        }

        &_desc {
            font-size: ${rem(14)};
            color: ${({ theme }) => theme.colors.grey28};
        }

        &.basketPage {
            .needHand_phone {
                border: unset;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &.snippet {
            font-size: ${rem(20)};
            background: #f5f5f5;

            .needHandSection_title {
                font-size: ${rem(24)};
                font-weight: bolder;
            }

            .descriptionFirst {
                color: ${({ theme }) => theme.colors.black2};
            }

            &_desc {
                font-size: ${rem(14)};
                color: ${({ theme }) => theme.colors.grey28};
            }

            button {
                background: transparent;

                &:hover {
                    background: ${({ theme }) => theme.colors.black};
                    border-color: ${({ theme }) => theme.colors.black};
                }
            }
        }

        .needHandSectionInlineContainer {
            .needHand_phone,
            .needHandSection_title {
                margin-right: ${rem(10)};
                font-size: ${rem(16)};
                color: ${({ theme }) => theme.colors.black};
            }

            .needHandSection_title {
                font-weight: normal;
            }
        }
    }
`;
