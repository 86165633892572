import { css } from 'src/styles/theme';
import styled from 'styled-components';
import { rem } from 'polished';
import { NeedHandSectionStyled } from '../NeedHandSectionStyled';
export const NeedHandSectionStyledAC = styled(NeedHandSectionStyled)`
    .needHandSection {
        &_title {
            font-size: ${rem(34)};
            color: ${({ theme }) => theme.colors.grey5};
            font-weight: bolder;
        }

        &_phone {
            font-size: ${rem(22)};
            color: ${({ theme }) => theme.colors.blue2};
            text-decoration: none;
            font-weight: bold;
        }

        &_desc {
            font-size: ${rem(14)};
            color: ${({ theme }) => theme.colors.grey1};
            line-height: ${rem(18)};
        }

        &.snippet {
            font-size: ${rem(20)};
            background: ${({ theme }) => theme.colors.grey6};
            border-radius: ${rem(12)};

            .needHandSection_title {
                font-size: ${rem(24)};
                font-weight: bolder;
            }

            .needHandSection_phone {
                font-size: ${rem(20)};
            }

            .descriptionFirst {
                color: ${({ theme }) => theme.colors.grey5};
            }

            &_desc {
                font-size: ${rem(14)};
                color: ${({ theme }) => theme.colors.grey28};
            }
            ${({ isInlineSnippet }) =>
                !isInlineSnippet &&
                css`
                    button {
                        background: transparent;

                        &:hover {
                            color: ${({ theme }) => theme.colors.black};
                            border-color: ${({ theme }) => theme.colors.black};
                        }
                    }
                `}
        }

        .needHandSectionInlineContainer {
            .needHand_phone,
            .needHandSection_title {
                margin-right: ${rem(10)};
                font-size: ${rem(16)};
                color: ${({ theme }) => theme.colors.black};
            }

            .needHandSection_title {
                font-weight: normal;
            }
        }

        &.basketPage {
            .needHandSection_desc {
                line-height: 1.43;
            }
        }
    }
`;
