import { ParsedUrlQuery } from 'querystring';
import { IGeoLocation } from '../../redux/filters/filter.duck.interface';
import { SortOrder, SortType } from './models/stockItems.service.model';

interface IGeoParams {
    lat: number;
    lng: number;
    distanceRadius: number;
}

export const getGeoParams = (geoLocation?: IGeoLocation, distanceRadius?: number): IGeoParams | undefined => {
    const isDistanceRadiusProvided = typeof distanceRadius === 'number';
    if (!isDistanceRadiusProvided || !geoLocation) {
        return;
    }

    return {
        lat: geoLocation.lat,
        lng: geoLocation.lng,
        ...(distanceRadius && distanceRadius !== 0 && { distanceRadius }),
    };
};

export const addSearchParams = (searchString?: string, param?: string, query?: ParsedUrlQuery): string => {
    switch (param) {
        case 'sortPrice':
            searchString = searchString.includes(`?`)
                ? `${searchString}&sort=${SortType.PRICE}%2C${SortOrder.ASC}`
                : `${searchString}?sort=${SortType.PRICE}%2C${SortOrder.ASC}`;
            break;

        case 'sortProximity':
            searchString = searchString.includes(`sort=${SortType.PRICE}`)
                ? searchString.replace(SortType.PRICE, SortType.PROXIMITY)
                : `${searchString}&sort=${SortType.PROXIMITY}:${SortOrder.ASC}`;
            break;

        case 'idsitegeo':
            const dealerId = query.idsitegeo as string;
            searchString = searchString.includes(`idsitegeo`) ? searchString : `${searchString}&idsitegeo=${dealerId}`;
            break;

        default:
            break;
    }

    return searchString;
};
