import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCalculateSummary, getSummaryOfFQ } from '../../../services/calculateSummary/calculateSummary.service';
import UIDuck from '../../../redux/commonDucks/ui.duck';
import {
    ICalculateSummaryRequestData,
    ICalculateSummaryResponse,
    IFinanceQuote,
} from '../../../services/calculateSummary/calculateSummary.types';
import { AxiosResponse } from 'axios';
import { getSessionIdCookie } from '@utils/Session.utils';
import { useFinanceWidgetDuck } from '@hooks/useFinanceWidgetDuck';
import { LEASYS_PROVIDER_TYPE, ModalVersion } from '../../../constants/main';
import { useJourneyType } from '@hooks/useJourneyType';

export const useSummaryDataPromise = (params: ICalculateSummaryRequestData, providerType?: string) => {
    const dispatch = useDispatch();

    const { financeWidgetParameters } = useFinanceWidgetDuck();

    const { isRent } = useJourneyType();

    const [_, setFinancialData] = useState<AxiosResponse<IFinanceQuote>>(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [processDataError, setProcessDataError] = useState<Error>(null);

    const openModal = (data: ICalculateSummaryResponse | {}) => {
        dispatch(
            UIDuck.openModal({
                data: {
                    financialData: data,
                    error: processDataError,
                    hideLegalNotice: isRent || providerType === LEASYS_PROVIDER_TYPE,
                    isLeasysDetail: providerType === LEASYS_PROVIDER_TYPE,
                },
                modalType: UIDuck.MODAL_TYPES.FINANCIAL_SUMMARY_CONFIGURATION,
                modalVersion: ModalVersion.v2,
            })
        );
    };

    const processCalculateSummaryDataPromise = useCallback(async () => {
        setFinancialData(() => null);
        setIsDataLoading(() => true);
        dispatch(UIDuck.setLoading(5));
        openModal({});
        setProcessDataError(null);
        try {
            const financeDataResult = await (params?.token
                ? getSummaryOfFQ(params.token, getSessionIdCookie())
                : getCalculateSummary({
                      ...params,
                      isPersonalized: Object.values(financeWidgetParameters || {}).length !== 0,
                      financeGatewayParameters: financeWidgetParameters,
                      ...(providerType && { providerType }),
                  }));
            setFinancialData(() => financeDataResult);
            setIsDataLoading(() => false);
            openModal(financeDataResult);
            dispatch(UIDuck.setLoading(-5));
        } catch (error: any) {
            setProcessDataError(error);
            dispatch(
                UIDuck.openModal({
                    data: {
                        financialData: {},
                        error: error,
                    },
                    modalType: UIDuck.MODAL_TYPES.FINANCIAL_SUMMARY_CONFIGURATION,
                    modalVersion: ModalVersion.v2,
                })
            );
        } finally {
            setIsDataLoading(() => false);
            dispatch(UIDuck.setLoading(-5));
        }
    }, [isDataLoading, params?.token, params?.carConfigId, financeWidgetParameters]);

    return { processCalculateSummaryDataPromise, error: processDataError, isDataLoading };
};
