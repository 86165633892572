import { breakpoints } from 'src/styles/theme';
import styled from 'styled-components';
import { rem } from 'polished';
import { publicAssets } from '@utils/url.utils';
import NeedHandSectionTemplate from './NeedHandSectionTemplate';
export const NeedHandSectionStyled = styled(NeedHandSectionTemplate)`
    display: flex;
    flex-direction: column;
    width: 100%;

    .needHelpBanner {
        background-image: url('${publicAssets('/images/needHand.jpg')}');
        background-size: cover;
        background-repeat: no-repeat;
        min-height: calc(100% - ${rem('200px')});
        background-position: 30% bottom;
    }

    .needHandSection {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: ${rem('20px')};

        &.snippet {
            font-size: ${rem(20)};
            background: ${({ theme }) => theme.colors.grey14};
        }

        .needHandSection_phone_nmb {
            white-space: nowrap;
        }

        &.basketPage {
            margin: ${rem('32px')} 0;

            .needHandSectionContainer {
                display: flex;
                width: 100%;
                flex-direction: column;

                .needHandSectionContentWrapper {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    @media (max-width: ${breakpoints.sm}px) {
                        flex-direction: column;
                    }

                    .needHandSectionWrapper {
                        width: 100%;
                        max-width: ${rem('360px')};
                        .needHandSection_phone {
                            margin-top: unset;
                        }
                    }
                }
            }
            button {
                max-width: fit-content;
                min-width: ${rem('300px')};
                margin: auto;
            }
        }

        &_title {
            font-size: ${rem(25)};
            word-break: break-word;
            color: ${({ theme }) => theme.colors.black2};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        &_phone {
            font-size: ${rem(16)};
            color: ${({ theme }) => theme.colors.blue2};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
            word-break: break-word;
            display: flex;
            align-items: center;

            svg {
                width: ${rem(30)};
                height: ${rem(30)};
                margin-bottom: -${rem(5)};

                path {
                    fill: ${({ theme }) => theme.colors.blue2} !important;
                }
            }
        }

        &_desc {
            font-size: ${rem(12)};
            color: ${({ theme }) => theme.colors.black};
            margin: ${rem(6)} 0 ${rem(16)} 0;
            margin-right: ${rem(15)};
        }

        .needHandSectionInlineContainer {
            display: flex;
            width: fit-content;
            justify-content: space-between;
            gap: ${rem(8)};
            margin: auto;

            .needHand_phone,
            .needHandSection_title {
                margin-right: ${rem(10)};
                font-size: ${rem(12)};
                color: ${({ theme }) => theme.colors.black};
            }

            .needHand_phone {
                font-weight: bolder;
            }

            @media (max-width: ${breakpoints.sm}px) {
                flex-direction: column;
                margin: unset;
            }
        }
    }
`;
