import styled from 'styled-components';
import { rem } from 'polished';
import { NeedHandSectionStyled } from '../NeedHandSectionStyled';
export const NeedHandSectionStyledAP = styled(NeedHandSectionStyled)`
    .needHandSection {
        .needHandSection_title {
            color: ${({ theme }) => theme.colors.black};
            text-transform: uppercase;
        }

        .needHandSection_phone {
            text-decoration: none;
            color: ${({ theme }) => theme.colors.black};
        }

        .needHandSection_desc {
            color: ${({ theme }) => theme.colors.black};
            line-height: 1.67;
        }

        &.basketPage {
            padding: ${rem('24px')};

            button {
                margin: ${rem('10px')} auto ${rem('10px')};
            }

            .needHand_phone,
            .needHandSection_title {
                margin-right: ${rem('4px')};
                line-height: initial;
            }
        }

        &.snippet {
            .needHandSection_title {
                font-size: ${({ isInlineSnippet }) => !isInlineSnippet && rem('20px')};
                font-weight: ${({ isInlineSnippet }) => isInlineSnippet && 'normal'};
            }
        }
    }
`;
