import React, { FC } from 'react';
import { NeedHandSectionProps } from '.';
import { useTranslations } from '@hooks/useTranslations';
import Icon, { Icons } from '@components/Icon';
import Button from '@components/Button';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import {ModalVersion, isBrandOV, isBrandAC, isMarketGB} from 'src/constants/main';
import { useDispatch } from 'react-redux';
import { usePlausible, UserEvent } from '@hooks/usePlausible';
import Link from '@components/Link';

const NEED_HAND_ELEMENT_ID = 'needHandSection';
const NeedHandSectionTemplate: FC<NeedHandSectionProps> = ({ className, isSidebar, isBasket, isInlineSnippet }) => {
    const { t } = useTranslations();
    const dispatch = useDispatch();

    const { plausibleUserEvent } = usePlausible();

    const handleNeedHelpClick = () => {
        plausibleUserEvent(UserEvent.ContactRequested);
        dispatch(
            UIDuck.openModal({
                data: {},
                modalType: UIDuck.MODAL_TYPES.NEED_HELP,
                modalVersion: ModalVersion.v2,
            })
        );
    };

    return (
        <div className={className}>
            <div
                className={`needHandSection ${isSidebar ? 'snippet' : ''} ${isBasket ? 'basketPage' : ''}`}
                id={NEED_HAND_ELEMENT_ID}
            >
                {!isInlineSnippet ? (
                    <>
                        <div className="needHandSectionContainer">
                            {isBasket && <div className="needHandSection_title">{t('home.needAHand.title')}</div>}
                            <div className="needHandSectionContentWrapper">
                                <div className="needHandSectionWrapper">
                                    {!isBasket && (
                                        <div
                                            className="needHandSection_title"
                                            data-testid="TESTING_NEED_A_HAND_SECTION_TITLE"
                                        >
                                            {t('home.needAHand.title')}
                                        </div>
                                    )}
                                    {(isSidebar || isBasket) && (
                                        <p className="needHandSection_desc descriptionFirst">
                                            {t('home.needAHand.description2')}
                                        </p>
                                    )}
                                </div>
                                <div className="needHandSectionWrapper">
                                    <a
                                        className="needHandSection_phone"
                                        href={`tel:${t('support.telephone')}`}
                                        data-testid="TESTING_NEED_A_HAND_SECTION_PHONE_LINK"
                                    >
                                        {t('home.needAHand.phoneLabel')}{' '}
                                        <Icon width={30} height={30} name={isBrandOV ? Icons.PhoneV2 : Icons.Phone} />{' '}
                                        <span className="needHandSection_phone_nmb">{t('support.telephone')}</span>
                                    </a>
                                    <p className="needHandSection_desc descriptionSecondary">
                                        {t('home.needAHand.description')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Button onClick={handleNeedHelpClick} secondary withoutBackground={isBrandAC || (isBrandOV && isMarketGB)}>
                            {t('home.needAHand.button')}
                        </Button>
                    </>
                ) : (
                    <div className="needHandSectionInlineContainer">
                        <div className="needHandSection_title">{t('header.nav.needHelp')}</div>
                        <Link
                            label={t('basket.needAHand.phoneLabel')}
                            onClick={handleNeedHelpClick}
                            primary
                            withArrowIcon
                        />
                        <a className="needHand_phone" href={`tel:${t('support.telephone')}`}>
                            {t('support.telephone')}
                        </a>
                    </div>
                )}
            </div>
            {!isSidebar && <div className="needHelpBanner" />}
        </div>
    );
};
export default NeedHandSectionTemplate;
