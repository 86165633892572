import { BRAND, isMarketGB } from 'src/constants/main';
import { NeedHandSectionStyled } from './NeedHandSectionStyled';
import { SC } from 'src/styles/theme';
import React from 'react';
import { NeedHandSectionStyledAC } from './AC/NeedHandSectionStyledAC';
import { NeedHandSectionStyledAP } from './AP/NeedHandSectionStyledAP';
import { NeedHandSectionStyledDS } from './DS/NeedHandSectionStyledDS';
import { NeedHandSectionStyledOV } from './OV/NeedHandSectionStyledOV';
import { NeedHandSectionStyledOVGB } from './OV/NeedHandSectionStyledOVGB';

export interface NeedHandSectionProps extends SC {
    isSidebar?: boolean;
    isBasket?: boolean;
    isInlineSnippet?: boolean;
}

export const NeedHandSection = (props: NeedHandSectionProps) => {
    if (BRAND === 'AC') return <NeedHandSectionStyledAC {...props} />;
    if (BRAND === 'AP') return <NeedHandSectionStyledAP {...props} />;
    if (BRAND === 'DS') return <NeedHandSectionStyledDS {...props} />;
    if (BRAND === 'OV')
        return isMarketGB ? <NeedHandSectionStyledOVGB {...props} /> : <NeedHandSectionStyledOV {...props} />;
    return <NeedHandSectionStyled {...props} />;
};

export default NeedHandSection;
