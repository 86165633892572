import styled from 'styled-components';
import { rem } from 'polished';
import { NeedHandSectionStyled } from '../NeedHandSectionStyled';
export const NeedHandSectionStyledDS = styled(NeedHandSectionStyled)`
    .needHandSection {
        .needHandSection_phone {
            text-decoration: none;
            font-size: ${rem(20)};
            margin-top: ${rem(14)};
            color: ${({ theme }) => theme.colors.black};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }

        .needHandSection_desc {
            line-height: 1.29;
            font-size: ${rem(14)};
            color: ${({ theme }) => theme.colors.grey29};
        }

        .needHandSection_title {
            font-size: ${rem(32)};
            color: ${({ theme }) => theme.colors.black};
            font-weight: normal;
        }

        .descriptionFirst {
            color: ${({ theme }) => theme.colors.black};
        }

        &.snippet {
            .needHandSection_title {
                font-size: ${rem(20)};
            }
            background: ${({ theme }) => theme.colors.grey4};
            border-radius: ${rem(8)};
        }

        .needHandSectionInlineContainer {
            .needHand_phone,
            .needHandSection_title {
                margin-right: ${rem(10)};
                font-size: ${rem(16)};
                color: ${({ theme }) => theme.colors.black};
            }

            .needHand_phone {
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }
`;
