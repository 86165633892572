import styled from 'styled-components';
import { css } from 'src/styles/theme';
import { rem } from 'polished';
import { NeedHandSectionStyled } from '../NeedHandSectionStyled';
export const NeedHandSectionStyledOVGB = styled(NeedHandSectionStyled)`
    .needHandSection {
        &.snippet {
            font-size: ${rem(20)};
            background: ${({ theme }) => theme.colors.grey10};

            .needHandSection_title {
                font-size: ${rem(24)};
                font-weight: bolder;
            }

            .descriptionFirst {
                color: ${({ theme }) => theme.colors.black2};
            }

            &_desc {
                font-size: ${rem(14)};
                color: ${({ theme }) => theme.colors.grey28};
            }
        }

        .needHandSectionInlineContainer {
            .needHand_phone,
            .needHandSection_title {
                margin-right: ${rem(10)};
                font-size: ${rem(16)};
                color: ${({ theme }) => theme.colors.black};
            }

            .needHandSection_title {
                font-weight: normal;
            }
        }

        .needHandSection_title {
            font-size: ${rem(30)};
            color: ${({ theme }) => theme.colors.black2};
            padding-bottom: ${rem(5)};
        }

        .needHandSection_phone {
            font-size: ${rem(19)};
            color: ${({ theme }) => theme.colors.black2};
            text-decoration: none;
            display: flex;
            align-items: baseline;

            svg {
                width: ${rem(25)};
                height: ${rem(25)};

                path {
                    fill: ${({ theme }) => theme.colors.white} !important;
                    stroke: ${({ theme }) => theme.colors.black2};
                }
            }
        }

        .needHandSection_desc {
            font-size: ${rem(14)};
            color: ${({ theme }) => theme.colors.black2};
        }
    }
`;
