import styled from 'styled-components';
import { PriceVatExcludedStyled } from '../PriceVatExcludedStyled';
import { rem } from 'polished';

export const PriceVatExcludedStyledAC = styled(PriceVatExcludedStyled)`
    font-weight: bold;
    font-size: ${rem('16px')};
    color: ${({ theme }) => theme.colors.grey1};

    & > .currencyLabel__suffix--OTR {
        font-size: ${rem('12px')};
        text-transform: unset;
    }
`;
