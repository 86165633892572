import PriceSuffix from '@components/PriceSuffix';
import React from 'react';
import { IPriceVatExcluded } from '.';

export const PriceVatExcludedTemplate = ({ price, className }: IPriceVatExcluded) => {
    return (
        <div className={`${className} cashPriceWithoutTax`}>
            <span>{price}</span>
            <PriceSuffix checkPrioritizedTranslation hideAsterisk showPriceExcludedVat />
        </div>
    );
};
